<template>
  <!-- eslint-disable -->
  <div>
    <v-row class="pa-3">
      <v-col cols="6" md="6">
        <h2>Send</h2>
        <v-btn color="indigo" @click.stop="sendMessage">
          Send
        </v-btn>
        <v-text-field
          v-model="messageSendText"
          color="purple darken-2"
          label="Message send"
          required
        ></v-text-field>
      </v-col>

      <v-col cols="6" md="6">
        <h2>Received</h2>
        <div v-for="msg in messageReceived">
          {{ msg }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */
/**
 * Send/receive stream preview images through WebRTC Datachannels
 */
import { mapGetters } from "vuex"
import WebrtcSender from "@/common/webrtc/webrtc-sender"
import WebrtcReceiver from "@/common/webrtc/webrtc-receiver"
import DatachannelSender from "@/common/webrtc/data-channel/datachannel-sender"
import DatachannelReceiver from "@/common/webrtc/data-channel/datachannel-receiver"

export default {
  data: () => ({
    sender: null,
    receiver: null,
    chSender: null,
    chReceiver: null,
    messageSendText: "",
    messageReceived: [],
    peerSender: null,
    peerreceiver: null,
  }),

  mounted() {
    let self = this
    let webrtcCommonProps = {
      signalUrl: "https://signal-dev.arhtengine.com:443",
      room: "data-channel-room",
    }
    // initiate WebRTC sender
    let senderProps = {
      ...webrtcCommonProps,
      ...{
        name: "DataChannelSender",
      },
    }
    self.sender = new WebrtcSender(senderProps)

    // initiate WebRTC receiver
    let receiverProps = {
      ...webrtcCommonProps,
      ...{
        name: "DataChannelReceiver",
      },
    }
    self.receiver = new WebrtcReceiver(receiverProps)
    self.startDataChannel()
  },

  methods: {
    startDataChannel() {
      let self = this
      // @todo Uncomment later handle instead of website sender peer - a ASP sender peer
      // self.sender.getOrCreateConnection().then((connSender) => {
      //   self.sender.getOrCreatePeerConnection(connSender.id, "local").then((peerSender) => {
      //     self.peerSender = peerSender.pc
      //     self.chSender = new DatachannelSender(self.peerSender)
      //     self.chSender.init("label-stream-preview-datachannel")
      //
      //     setTimeout(() => {
      //       self.receiver.getOrCreateConnection().then((connReceiver) => {
      //         self.receiver.getOrCreatePeerConnection(connSender.id, "remote").then((peerReceiver) => {
      //           setTimeout(() => {
      //           self.receiver.makeOfferPreview(
      //             { id: connSender.id },
      //             self.sender
      //           )
      //           console.log("connSender.id", connSender.id)
      //           console.log("connReceiver.id", connReceiver.id)
      //
      //           setTimeout(() => {
      //
      //             self.peerReceiver = peerReceiver.pc
      //
      //             // self.peerSender.addEventListener('connectionstatechange', event => {
      //             //   console.log("connectionstatechange--peerSender", event)
      //             // })
      //             // self.peerReceiver.addEventListener('connectionstatechange', event => {
      //             //   console.log("connectionstatechange--peerReceiver", event)
      //             // })
      //
      //             self.peerSender.onconnectionstatechange = function (event) {
      //               console.log(
      //                 "onconnectionstatechange--sender",
      //                 self.peerSender.connectionState
      //               )
      //             }
      //
      //             self.chReceiver = new DatachannelReceiver(self.peerReceiver)
      //             self.chReceiver.on("message", self.handleDataChannelMessage)
      //
      //           }, 1000)
      //           }, 1000)
      //         })
      //       })
      //     }, 1000)
      //   })
      // })


      // In case when do not use signal server for establish p2p connection - for test only
      self.sender.createPlainPeerConnection().then((peerSender) => {
        self.peerSender = peerSender.pc
        self.peerSender.oniceconnectionstatechange = function (event) {
          console.log("ICE Candidate State self.peerSender: " + self.peerSender.iceConnectionState);
          console.log("ICE Gathering State self.peerSender: " + self.peerSender.iceGatheringState);
        }

        self.chSender = new DatachannelSender({peer: self.peerSender, label: "label-stream-preview-datachannel"})

        self.peerSender.onicecandidate = e => {
          self.onIceCandidate(self.peerReceiver, e)
        }

        self.receiver.createPlainPeerConnection().then((peerReceiver) => {
          self.peerReceiver = peerReceiver.pc
          self.peerReceiver.onicecandidate = e => {
            self.onIceCandidate(self.peerSender, e)
          }
          self.peerReceiver.oniceconnectionstatechange = function (event) {
            console.log("ICE Candidate State self.peerReceiver: " + self.peerReceiver.iceConnectionState);
            console.log("ICE Gathering State self.peerReceiver: " + self.peerReceiver.iceGatheringState);
          }

          self.chReceiver = new DatachannelReceiver({peer: self.peerReceiver})
          self.chReceiver.on("message", self.handleDataChannelMessage)

          self.peerSender.createOffer().then(
            self.gotDescription1,
            self.onCreateSessionDescriptionError
          )
        })
      })
    },
    handleDataChannelMessage(msg) {
      console.log("handleDataChannelMessage", msg)
      this.messageReceived.push(msg)
    },
    sendMessage(){
      console.log("sendMessage", this.messageSendText, this.chSender)
      console.log("connectionState--peerReceiver", this.peerReceiver.connectionState)
      console.log("connectionState--peerSender", this.peerSender.connectionState)
      this.chSender.sendData(this.messageSendText)
    },
    // Methods for handle webrtc p2p connection without signal server - REMOVE LATER
    onIceCandidate(pc, event) {
      let self = this
      console.log("onIceCandidate", pc, event)
      pc
        .addIceCandidate(event.candidate)
        .then(
          self.onAddIceCandidateSuccess,
          self.onAddIceCandidateError
        );
      console.log(` ICE candidate: ${event.candidate ? event.candidate.candidate : '(null)'}`);
    },
    onAddIceCandidateSuccess() {
      console.log('AddIceCandidate success.');
    },
    onAddIceCandidateError(error) {
      console.log(`Failed to add Ice Candidate: ${error.toString()}`);
    },
    gotDescription1(desc) {
      let self = this
      self.peerSender.setLocalDescription(desc)
      console.log("gotDescription1--", JSON.stringify(desc), self.peerSender)
      console.log(`Offer from localConnection\n${desc.sdp}`)
      self.peerReceiver.setRemoteDescription(desc)
      self.peerReceiver.createAnswer().then(
        self.gotDescription2,
        self.onCreateSessionDescriptionError
      )
    },
    gotDescription2(desc) {
      this.peerReceiver.setLocalDescription(desc)
      console.log("gotDescription1--", JSON.stringify(desc))
      console.log(`Answer from remoteConnection\n${desc.sdp}`)
      this.peerSender.setRemoteDescription(desc)
    },
    onCreateSessionDescriptionError(error) {
      console.log('Failed to create session description: ' + error.toString())
    },
  },

  computed: {
    ...mapGetters({}),
  },
}
</script>
