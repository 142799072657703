import EventEmitter from "events"

export default class DatachannelSender extends EventEmitter {
  peer = null
  channel = null
  readyState = null

  constructor({ peer, label = "dchSender", options = {} }) {
    super()
    this.peer = peer
    this.init(label, options)
  }

  init(label, options = {}) {
    this.channel = this.peer.createDataChannel(label, options)
    console.log("this.channel", this.channel)
    this.channel.onopen = this.onChannelStateChange.bind(this)
    this.channel.onclose = this.onChannelStateChange.bind(this)
  }

  onChannelStateChange() {
    console.log("this.channel--onChannelStateChange", this.channel, this.peer)
    this.readyState = this.channel.readyState
    console.log("Send channel state is: " + this.readyState)
    if (this.readyState === "open") {
      // dataChannelSend.disabled = false;
      // dataChannelSend.focus();
      // sendButton.disabled = false;
      // closeButton.disabled = false;
    } else {
      // dataChannelSend.disabled = true;
      // sendButton.disabled = true;
      // closeButton.disabled = true;
    }
  }

  sendData(data) {
    console.log("sendData", this.channel)
    this.channel.send(data)
    console.log("Sent Data: " + data)
  }

  close() {
    console.log("Closing sender data channels")
    this.channel.close()
  }
}
