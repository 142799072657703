import EventEmitter from "events"

export default class DatachannelReceiver extends EventEmitter {
  peer = null
  channel = null
  readyState = null

  constructor({ peer }) {
    super()
    this.peer = peer
    this.init()
  }

  init() {
    this.peer.ondatachannel = this.receiveChannelCallback.bind(this)
  }

  receiveChannelCallback(event) {
    console.log("Receive Channel Callback")
    this.channel = event.channel
    this.channel.onmessage = this.onMessageCallback.bind(this)
    this.channel.onopen = this.onChannelStateChange.bind(this)
    this.channel.onclose = this.onChannelStateChange.bind(this)
    this.emit("receiveChannelCallback")
  }

  onChannelStateChange() {
    this.readyState = this.channel.readyState
    console.log("Receive channel state is: " + this.readyState)
  }

  onMessageCallback(event) {
    console.log("onMessageCallback: " + event.data)
    this.emit("message", event.data)
  }

  close() {
    console.log("Closing sender data channels")
    this.channel.close()
  }
}
